<template>
  <div class="bg-card">
    <div v-if="dataLoading" class="spinner-border m-auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div v-else style="width: 100%">
      <div v-if="can_list">
        <div class="row">
          <div class="col-md-6">
            <DxPieChart
                id="problemTypePie"
                :data-source="problemTypeData"
                palette="Bright"
                :title="$t('pivot.type')"
                @point-click="pointClickHandler($event)"
                @legend-click="legendClickHandler($event)"
            >
              <DxSeries argument-field="problemType" value-field="count">
                <DxLabel :visible="true" :customize-text="formatLabel">
                  <DxConnector :visible="true" :width="1"/>
                </DxLabel>
              </DxSeries>
              <DxTooltip
                  :customize-tooltip="customizeTooltipproblemPie"
                  :enabled="true"
              />
              <DxSize :width="500"/>
              <DxExport :enabled="true"/>
              <DxLegend
                  vertical-alignment="bottom"
                  horizontal-alignment="center"
              />
            </DxPieChart>
          </div>
          <div class="col-md-6">
            <DxPieChart
                id="problemPie"
                :data-source="problemData"
                palette="Bright"
                :title="$t('pivot.problem')"
                @point-click="pointClickHandler($event)"
                @legend-click="legendClickHandler($event)"
            >
              <DxSeries argument-field="problemSource" value-field="count">
                <DxLabel :visible="true" :customize-text="formatLabel">
                  <DxConnector :visible="true" :width="1"/>
                </DxLabel>
              </DxSeries>
              <DxTooltip
                  :customize-tooltip="customizeTooltipproblemPie"
                  :enabled="true"
              />
              <DxSize :width="500"/>
              <DxExport :enabled="true"/>
              <DxLegend
                  vertical-alignment="bottom"
                  horizontal-alignment="center"
              />
            </DxPieChart>
          </div>
          <div class="col-md-6">
            <DxPieChart
                id="problemActivePie"
                :data-source="problemPassiveData"
                palette="Bright"
                :title="$t('pivot.passiveProblem')"
                @point-click="pointClickHandler($event)"
                @legend-click="legendClickHandler($event)"
            >
              <DxSeries argument-field="problemType" value-field="count">
                <DxLabel :visible="true" :customize-text="formatLabel">
                  <DxConnector :visible="true" :width="1"/>
                </DxLabel>
              </DxSeries>
              <DxTooltip
                  :customize-tooltip="customizeTooltipproblemPie"
                  :enabled="true"
              />
              <DxSize :width="500"/>
              <DxExport :enabled="true"/>
              <DxLegend
                  vertical-alignment="bottom"
                  horizontal-alignment="center"
              />
            </DxPieChart>
          </div>
          <div class="col-md-6">
            <DxPieChart
                id="problemPassivePie"
                :data-source="problemActiveData"
                palette="Bright"
                :title="$t('pivot.activeProblem')"
                @point-click="pointClickHandler($event)"
                @legend-click="legendClickHandler($event)"
            >
              <DxSeries argument-field="problemType" value-field="count">
                <DxLabel :visible="true" :customize-text="formatLabel">
                  <DxConnector :visible="true" :width="1"/>
                </DxLabel>
              </DxSeries>
              <DxTooltip
                  :customize-tooltip="customizeTooltipproblemPie"
                  :enabled="true"
              />
              <DxSize :width="500"/>
              <DxExport :enabled="true"/>
              <DxLegend
                  vertical-alignment="bottom"
                  horizontal-alignment="center"
              />
            </DxPieChart>
          </div>
        </div>

        <fullscreen :fullscreen.sync="fullscreen1" background="fac78f">
          <div class="fullscreen-wrapper">
            <DxChart ref="chart">
              <DxTooltip :enabled="true"/>
              <DxAdaptiveLayout :width="450"/>
              <DxSize :height="350"/>
              <DxCommonSeriesSettings type="bar"/>
              <DxTooltip
                  :customize-tooltip="customizeTooltip"
                  :enabled="true"
              />
              <DxLegend
                  vertical-alignment="bottom"
                  horizontal-alignment="center"
              />
            </DxChart>

            <DxPivotGrid
                id="pivotgrid"
                ref="grid"
                :data-source="dataSource1"
                :allow-sorting-by-summary="true"
                :allow-filtering="true"
                :show-borders="true"
                :show-column-grand-totals="false"
                :show-row-grand-totals="true"
                :show-row-totals="true"
                :show-column-totals="false"
                :column-auto-width="true"
                :show-totals-prior="showTotalsPrior"
                :data-field-area="dataFieldArea"
                :allow-expand-all="true"
                :row-header-layout="rowHeaderLayout"
                :word-wrap-enabled="false"
                @cell-click="onCellClick"
            >
              <DxFieldChooser :enabled="true" :height="400"/>
            </DxPivotGrid>
            <DxPopup
                :width="1000"
                :height="400"
                :title="popupTitle"
                :visible="popupVisible"
                @shown="onShown"
            >
              <DxDataGrid
                  :ref="dataGridRefName"
                  :width="560"
                  :height="300"
                  :data-source="drillDownDataSource"
              >
                <DxColumn caption="Problem Source" data-field="problemSource"></DxColumn>
                <DxColumn caption="Problem Type" data-field="problemType"></DxColumn>
                <DxColumn caption="Problem" data-field="problem"></DxColumn>
                <DxColumn caption="Date" data-field="work_date" data-type="date"></DxColumn>
                <DxColumn caption="Problem Duration Day" data-field="problemDurationDay" data-type="number"></DxColumn>
                <DxColumn caption="Explanation" data-field="explanation" data-type="string"></DxColumn>
              </DxDataGrid>
            </DxPopup>
          </div>
        </fullscreen>
        <DxButton
            :width="120"
            class="fullscren"
            text="Fullscreen"
            styling-mode="contained"
            @click="toggle1"
        />
        <div class="dxbtn">
          <DxButton
              class="btn"
              hint="Route"
              icon="arrowright"
              text="Route to details"
              :visible="true"
              :disabled="false"
              :on-click="routeToDetail"
          />
        </div>
      </div>
      <h1 v-else>{{ $t('noPermission') }}</h1>
    </div>
  </div>
</template>

<script>
import {useUtils as useI18nUtils} from '@core/libs/i18n';

const {t} = useI18nUtils();
import {DxPivotGrid, DxFieldChooser} from 'devextreme-vue/pivot-grid';
import {DxButton} from 'devextreme-vue/button';
import {DxPopup} from 'devextreme-vue/popup';
import DxPieChart, {
  DxSize,
  DxSeries,
  DxLabel,
  DxConnector,
  DxExport
} from 'devextreme-vue/pie-chart';
import axios from '@/libs/axios';
import {
  DxChart,
  DxAdaptiveLayout,
  DxCommonSeriesSettings,
  DxTooltip,
  DxLegend
} from 'devextreme-vue/chart';
import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxFilterRow,
  DxSearchPanel,
  DxLookup,
  DxScrolling,
  DxSummary,
  DxFormItem,
  DxGroupItem,
  DxGroupPanel,
  DxGrouping,
  DxEditing,
  DxPaging,
  DxPager
} from 'devextreme-vue/data-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Vue from 'vue';
import fullscreen from 'vue-fullscreen';
import {v4 as uuidv4} from 'uuid';

Vue.use(fullscreen);

export default {
  components: {
    DxPivotGrid,
    DxButton,
    DxFieldChooser,
    DxPieChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxTooltip,
    DxLegend,
    DxDataGrid,
    DxColumn,
    DxPopup
  },
  data() {
    return {
      fullscreen1: false,
      areas: [],
      error: '',
      dataLoading: false,
      dataSource: {},
      problemTypeData: [],
      problemData: [],
      dataSource1: [],
      problemActiveData: [],
      problemPassiveData: [],
      showTotalsPrior: 'none',
      showColumnTotals: false,
      showRowGrandTotals: false,
      showColumnGrandTotals: false,
      showRowTotals: false,
      dataFieldArea: 'column',
      pivotHead: 'FamaMetric',
      rowHeaderLayout: 'none',
      allowCrossGroupCalculation: true,
      can_list: true,
      dataGridRefName: 'dataGrid',
      drillDownDataSource: null,
      popupTitle: '',
      popupVisible: false,
    };
  },
  computed: {
    dataGrid() {
      return this.$refs[this.dataGridRefName].instance;
    },
  },
  async mounted() {
    await this.getPageAuths();
    await this.getWorkData();
  },
  methods: {
    onShown() {
      this.dataGrid.updateDimensions();
    },
    onCellClick(e) {
      if (e.area === 'data') {
        const pivotGridDataSource = e.component.getDataSource();
        const rowPathLength = e.cell.rowPath.length;
        const rowPathName = e.cell.rowPath[rowPathLength - 1];
        this.drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(e.cell);
        this.popupTitle = `${
            rowPathName || 'Total'
        } Drill Down Data`;
        this.popupVisible = !this.popupVisible;
      }
    },
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Problem Report'
      });
      this.can_list = pageAuths.data.can_list;
    },
    customizeTooltip(args) {
      const valueText =
          args.seriesName.indexOf('Total') !== -1
              ? new Intl.NumberFormat('en-EN', {
                style: 'currency',
                currency: 'USD'
              }).format(args.originalValue)
              : args.originalValue;

      return {
        html: `${args.seriesName}<div class='currency'>${valueText}</div>`
      };
    },
    toggle1() {
      this.fullscreen1 = !this.fullscreen1;
    },
    formatLabel(pointInfo) {
      return `${pointInfo.valueText} (${pointInfo.percentText})`;
    },
    routeToDetail(e) {
      this.$router.push('/tables/generalProblem');
    },
    customizeTooltipproblemPie({percentText, valueText}) {
      return {
        text: `${percentText} - ${valueText}`
      };
    },
    getProblemTypes() {
      this.areas.forEach((a) => {
        if (a.problemType) {
          const check = this.problemTypeData.find(
              (p) => p.problemType === a.problemType
          );
          if (check === undefined) {
            this.problemTypeData.push({
              problemType: a.problemType,
              count: 1
            });
          } else {
            check.count += 1;
          }
        }
      });
    },
    getProblem() {
      this.areas.forEach((a) => {
        if (a.problemSource) {
          const check = this.problemData.find(
              (p) => p.problemSource === a.problemSource
          );
          if (check === undefined) {
            this.problemData.push({
              problemSource: a.problemSource,
              count: 1
            });
          } else {
            check.count += 1;
          }
        }
      });
    },
    getActiveProblem() {
      this.areas.forEach((a) => {
        if (a.problemType) {
          if (a.problemIsResolved === true) {
            const check = this.problemPassiveData.find(
                (p) => p.problemType === a.problemType
            );
            if (check === undefined) {
              this.problemPassiveData.push({
                problemType: a.problemType,
                count: 1
              });
            } else {
              check.count += 1;
            }
          }
        }
      });
    },
    getPassiveProblem() {
      this.areas.forEach((a) => {
        if (a.problemType) {
          if (a.problemIsResolved !== true) {
            const check = this.problemActiveData.find(
                (p) => p.problemType === a.problemType
            );
            if (check === undefined) {
              this.problemActiveData.push({
                problemType: a.problemType,
                count: 1
              });
            } else {
              check.count += 1;
            }
          }
        }
      });
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async getWorkData() {
      this.dataLoading = true;
      this.error = '';
      let response = await axios.get('/list-work-by-pivot-v2');
      this.dataLoading = false;

      if (response.status === 200) {
        try {
          this.areas = response?.data?.allProblems || [];

          this.areas.forEach((o) => {
            o.problemId = uuidv4;
            if (o.problemIsResolved !== undefined) {
              if (o.problemIsResolved !== true) {
                o.problemIsResolved = false;
              }
            }
            o.problemSource = this.$t(o.problemSource);
            o.problemIsResolvedName = o.problemIsResolved
                ? 'Passive'
                : 'Active';
            o.problemIsResolvedName = this.$t(o.problemIsResolvedName);
          });
          this.getProblemTypes();
          this.getProblem();
          this.getActiveProblem();
          this.getPassiveProblem();
          this.dataSource1 = new PivotGridDataSource({
            fields: [
              {
                caption: 'Problem Source',
                dataField: 'problemSource',
                area: 'row',
                width: 100
              },
              {
                caption: 'Problem Type',
                dataField: 'problemType',
                area: 'row',
                width: 100
              },
              {
                caption: 'Problem',
                dataField: 'problem',
                area: 'row',
                width: 100
              },
              {
                dataField: 'work_date',
                dataType: 'date',
                area: 'column'
              },
              {
                groupName: 'work_date',
                dataType: 'date',
                groupInterval: 'week',
                selector: function (data) {
                  let currentDate = new Date(data.work_date);
                  let startDate = new Date(currentDate.getFullYear(), 0, 1);
                  var days = Math.floor(
                      (currentDate - startDate) / (24 * 60 * 60 * 1000)
                  );

                  var weekNumber = Math.ceil(days / 7);
                  return weekNumber;
                },
                visible: true
              },
              {
                dataField: 'problem',
                visible: false
              },
              {
                groupName: 'work_date',
                dataType: 'date',
                groupInterval: 'day',
                visible: true
              },
              {
                caption: 'Problem Is Resolved',
                dataField: 'problemIsResolvedName',
                summaryType: 'count',
                area: 'column'
              },
              {
                dataField: 'explanation',
                visible: true,
              },
              {
                caption: 'Problem Duration Day',
                dataField: 'problemDurationDay',
                dataType: 'number',
                summaryType: 'sum',
                // area: 'row',
                visible: true
              },
              {
                caption: 'Problem Count',
                dataField: 'problemId',
                summaryType: 'count',
                area: 'data'
              }
            ],
            store: this.areas
          });
          setTimeout(() => {
            const pivotGrid = this.$refs.grid.instance;
            const chart = this.$refs.chart.instance;
            pivotGrid.bindChart(chart, {
              dataFieldsDisplayMode: 'splitPanes',
              alternateDataFields: false
            });
          }, 1000);
        } catch (e) {
        }
      } else {
        this.error = response?.data?.message || 'Unexpected API error';
        this.dataLoading = false;
      }
    },
    pointClickHandler(e) {
      this.toggleVisibility(e.target);
    },
    legendClickHandler(e) {
      const arg = e.target;
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

      this.toggleVisibility(item);
    },
    toggleVisibility(item) {
      item.isVisible() ? item.hide() : item.show();
    }
  }
};
</script>

<style>
#pie {
  height: 440px;
}

/*.col-md-6{*/
/*    background: #ffffff;*/
/*}*/
.bg-card {
  background: #ffffff;
  /*height: 2050px;*/
  height: auto;
}

.btn {
  margin-left: 50px;
  margin-bottom: 10px;
}

#pie * {
  margin: 0 auto;
}

.dxbtn {
  justify-content: flex-end;
  display: block;
}

.fullscren {
  margin-left: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.fullscreen-wrapper {
  background: #ffffff;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
</style>
